//-----------------------
// S10-Pricing Component
//-----------------------

// PACKAGES
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

// COMPONENTS
// import BuyButton from "../../molecules/SnipcartBuyButton"
// import ReprintButton from "../../molecules/SnipcartReprintButton"
import IconCheckCoral from "../../../assets/icons/check-coral.inline.svg" // Tell Webpack this JS file uses this image
import IconTruck from "../../../assets/icons/truck.inline.svg"
import IconGlobe from "../../../assets/icons/globe.inline.svg"
import IconRefund from "../../../assets/icons/refund.inline.svg"
import CheckoutButton from "../../molecules/CheckoutButton"

// COMPONENT BUILD
const Pricing = ({ pricingOrderCTA, pricingReprintCTA }) => {
    // DATA QUERY
    const data = useStaticQuery(graphql`
        query {
            markdownRemark(frontmatter: {type: {eq: "landing"}}) {
                frontmatter {
                    pricing {
                        pricing_title_half_1
                        pricing_title_half_2
                        pricing_subtitle_half_1
                        pricing_subtitle_half_2
                        pricing_order_card_title
                        pricing_order_card_subtitle_half_1
                        pricing_order_card_subtitle_half_2
                        pricing_order_card_original_price
                        pricing_order_card_offer_price
                        pricing_order_card_CTA
                        pricing_reprint_card_title
                        pricing_reprint_card_subtitle_half_1
                        pricing_reprint_card_subtitle_half_2
                        pricing_reprint_card_price
                        pricing_reprint_card_CTA
                        pricing_product_feature_1
                        pricing_product_feature_2
                        pricing_product_feature_3
                        pricing_product_feature_4
                        pricing_product_feature_5
                        pricing_product_feature_6
                    }
                }
            }
        }
    `)

    // JSX TEMPLATE
    return (
        <section className="pt-5 pb-7 pb-lg-10" id="pricing">
            <div className="container px-4 text-center mb-n11">
                <h2 className="mb-6 text-center underline">
    {data.markdownRemark.frontmatter.pricing.pricing_title_half_1}{" "}<br className="pricing-br d-block d-lg-none"/>{data.markdownRemark.frontmatter.pricing.pricing_title_half_2}
                </h2>
                <p className="text-center lead lead-small text-muted font-italic">
                    {data.markdownRemark.frontmatter.pricing.pricing_subtitle_half_1}{" "}
                    <br className="d-none d-md-block"/>
                    {data.markdownRemark.frontmatter.pricing.pricing_subtitle_half_2}
                </p>
                <div className="mt-8 row justify-content-center align-items-end mt-md-9">
                    <div className="col-md-6">
                        <div className="px-5 pb-4 border shadow bg-primary pt-7">
                            <h3 className="mb-3 text-white font-weight-bold header-sans">
                                New Home Guide
                            </h3>
                            <p className="text-white font-italic">
                            {data.markdownRemark.frontmatter.pricing.pricing_order_card_subtitle_half_1}{" "}<br className="d-none d-sm-block" />or{" "}
                            {data.markdownRemark.frontmatter.pricing.pricing_order_card_subtitle_half_2}
                            </p>
                            <h4 className="my-4 text-muted-coral">
                                <del>{data.markdownRemark.frontmatter.pricing.pricing_order_card_original_price}</del>
                            </h4>
                            <h2 className="mt-4 mb-5 text-white">{data.markdownRemark.frontmatter.pricing.pricing_order_card_offer_price}</h2>
                            {/* <BuyButton 
                            pricingOrderCTA={data.markdownRemark.frontmatter.pricing.pricing_order_card_CTA}
                            /> */}
                            <CheckoutButton
                                secondary
                                caption={data.markdownRemark.frontmatter.pricing.pricing_order_card_CTA}
                                ariaLabel="Buy product for $69.99"    
                            />
                        </div>
                    </div>
                    <div className="mt-6 col-md-6 col-lg-5 mt-lg-8">
                        <div className="px-5 pt-6 pb-4 bg-white border shadow border-ie-lighter px-sm-4 px-lg-6 px-7-xl">
                            <h3 className="mb-3 font-weight-bold header-sans">
                            {data.markdownRemark.frontmatter.pricing.pricing_reprint_card_title}
                            </h3>
                            <p className="font-italic">
                            {data.markdownRemark.frontmatter.pricing.pricing_reprint_card_subtitle_half_1}{" "}<br className="d-none d-sm-block" />{data.markdownRemark.frontmatter.pricing.pricing_reprint_card_subtitle_half_2}
                            </p>
                            <h2 className="mt-4 mb-5 text-ie-black">{data.markdownRemark.frontmatter.pricing.pricing_reprint_card_price}</h2>
                            {/* <ReprintButton
                            pricingReprintCTA={data.markdownRemark.frontmatter.pricing.pricing_reprint_card_CTA}
                            /> */}
                            <CheckoutButton
                                reorder
                                caption={data.markdownRemark.frontmatter.pricing.pricing_reprint_card_CTA}
                                ariaLabel="Reorder product for $34.99"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container px-0 px-4 pt-11 px-lg-10 frame frame-hide">
                <div className="mt-8 row">
                    <div className="mx-auto mb-3 col-10 col-md-6 col-lg-4 d-flex align-items-start pl-md-7 pl-lg-0">
                        <div className="px-0 col-2">
                            <IconCheckCoral className="mt-1 mr-4" height="20px"/>
                        </div>  
                        <div className="px-0 col-10">
                            <p className="text-ie-black">
                            {data.markdownRemark.frontmatter.pricing.pricing_product_feature_1}
                            </p>
                        </div>
                    </div>

                    <div className="mx-auto mb-3 col-10 col-md-6 col-lg-4 d-flex align-items-start pl-md-7 pl-lg-7">
                        <div className="px-0 col-2">
                            <IconCheckCoral className="mt-1 mr-4" height="20px"/>
                        </div>
                        <div className="px-0 col-10">
                            <p className="text-ie-black">{data.markdownRemark.frontmatter.pricing.pricing_product_feature_2}</p>
                        </div>
                    </div>

                    <div className="mx-auto mb-3 col-10 col-md-6 col-lg-4 d-flex align-items-start pl-md-7 pl-lg-7">
                        <div className="px-0 col-2">
                            <IconCheckCoral className="mt-1 mr-4" height="20px"/>
                        </div>
                        <div className="px-0 col-10">
                            <p className="text-ie-black">{data.markdownRemark.frontmatter.pricing.pricing_product_feature_3}</p>
                        </div>
                    </div>

                    <div className="mx-auto mb-3 col-10 col-md-6 col-lg-4 d-flex align-items-start pl-md-7 pl-lg-0 mb-lg-8">
                        <div className="px-0 col-2">
                            <IconCheckCoral className="mt-1 mr-4" height="20px"/>
                        </div>
                        <div className="px-0 col-10">
                            <p className="text-ie-black">
                            {data.markdownRemark.frontmatter.pricing.pricing_product_feature_4}
                            </p>
                        </div>
                    </div>

                    <div className="mx-auto mb-3 col-10 col-md-6 col-lg-4 d-flex align-items-start pl-md-7 pl-lg-7 mb-md-8">
                        <div className="px-0 col-2">
                            <IconCheckCoral className="mt-1 mr-4" height="20px"/>
                        </div>
                        <div className="px-0 col-10">
                            <p className="text-ie-black">{data.markdownRemark.frontmatter.pricing.pricing_product_feature_5}</p>
                        </div>
                    </div>

                    <div className="mx-auto mb-3 mb-8 col-10 col-md-6 col-lg-4 d-flex align-items-start pl-md-7 pl-lg-7">
                        <div className="px-0 col-2">
                            <IconCheckCoral className="mt-1 mr-4" height="20px"/>
                        </div>
                        <div className="px-0 col-10">
                            <p className="text-ie-black">{data.markdownRemark.frontmatter.pricing.pricing_product_feature_6}</p>
                        </div>
                    </div>
                </div>
                <div className="text-center row">
                    <div className="mb-4 col-4 col-md-4">
                         <IconTruck
                            className="mb-4 free-shipping"
                            height="80px"
                        />
                        <p className="text-ie-black font-italic">Free <br className="d-block d-sm-none"/>shipping</p>
                    </div>
                    <div className="mb-4 col-4 col-md-4">
                        <IconGlobe
                            className="mb-4 worldwide-service"
                            height="80px"
                        />
                        <p className="text-ie-black font-italic">Worldwide <br className="d-block d-sm-none"/>service</p>
                    </div>
                    <div className="mb-4 col-4 col-md-4">
                        <IconRefund
                            className="mb-4 moneyback-guarantee"
                            height="80px"
                        />
                        <p className="text-ie-black font-italic">Moneyback <br className="d-block d-sm-none"/>guarantee</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pricing
