//-----------------------
// S1-Masthead Component
//-----------------------

// PACKAGES
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"

// COMPONENTS
import Image from "../../atoms/Image" // Add images by passing files to the Image component instances with  named graphql querys
import CheckoutButton from "../../molecules/CheckoutButton"

// SVG
import IconScroll from "../../../assets/icons/scroll.inline.svg"

// COMPONENT BUILD
const Masthead = () => {
    // DATA QUERY
    const data = useStaticQuery(graphql`
        query {
            Image1: file(relativePath: { eq: "Mockup_Demo1-900x1262.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 700) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
            markdownRemark(frontmatter: {type: {eq: "landing"}}) {
                frontmatter {
                    masthead {
                        masthead_title
                        masthead_subtitle
                        masthead_cta
                    }
                }
              }
        }
    `)

    // JSX TEMPLATE
    return (
        <section className="masthead-padding masthead-image-parent">
            <div className="container px-4">
                <div className="px-0 mb-5 early-bird-banner-sm col m-md-0 alig-content-center">
                    <div className="mr-4 bg">
                        <div className="shake">
                            <div className="element">
                                <svg
                                    width="31"
                                    height="32"
                                    viewBox="0 0 31 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.3678 5.76594V3.60756H17.9798C18.4448 3.60756 18.8478 3.23756 18.8478 2.74421C18.8478 2.25087 18.4758 1.88086 17.9798 1.88086H13.0508C12.5858 1.88086 12.1828 2.25087 12.1828 2.74421C12.1828 3.23756 12.5548 3.60756 13.0508 3.60756H14.6318V5.76594C8.09082 6.19762 2.91382 11.6244 2.91382 18.2229C2.91382 25.1297 8.55582 30.7415 15.4688 30.7415C22.3818 30.7415 28.0238 25.1297 28.0238 18.2229C28.0548 11.6244 22.8778 6.19762 16.3678 5.76594ZM15.4998 29.0456C9.51682 29.0456 4.64982 24.2047 4.64982 18.2229C4.64982 16.7737 4.95982 15.2936 5.54882 13.9678C6.10682 12.6728 6.94382 11.4702 7.96682 10.4835C8.98982 9.49686 10.2298 8.69517 11.5318 8.17099C12.1518 7.92432 12.8028 7.73932 13.4538 7.61598C15.0968 7.30764 16.8328 7.36931 18.4448 7.83182C19.8088 8.23266 21.1108 8.88018 22.2268 9.77436C23.3428 10.6685 24.2728 11.7477 24.9548 12.9811C25.6678 14.2453 26.1018 15.6637 26.2568 17.082C26.2878 17.452 26.3188 17.822 26.3188 18.192C26.3498 24.1738 21.4828 29.0456 15.4998 29.0456Z"
                                        fill="#444444"
                                    />
                                    <path
                                        d="M21.8857 18.0996H16.3677V11.6553C16.3677 11.1928 15.9957 10.792 15.4997 10.792C15.0037 10.792 14.6317 11.162 14.6317 11.6553V18.0996H13.3607C12.8957 18.0996 12.4927 18.4697 12.4927 18.963C12.4927 19.4563 12.8647 19.8264 13.3607 19.8264H14.6317V21.0905C14.6317 21.5531 15.0037 21.9539 15.4997 21.9539C15.9957 21.9539 16.3677 21.5839 16.3677 21.0905V19.8264H21.8857C22.3507 19.8264 22.7537 19.4563 22.7537 18.963C22.7537 18.4697 22.3507 18.0996 21.8857 18.0996Z"
                                        fill="#444444"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <p className="my-auto mb-0 text-left text-center early-bird-banner-sm-text early-bird-banner-text text-ie-black">
                        Early bird price for limited time.
                    </p>
                </div>
                <div className="row">
                    <div className="my-auto col-md-8 col-lg-6 col-xl-7">
                        <h1 className="mb-5 pr-xs-0 pr-sm-10 pr-md-2 pr-xl-5 masthead-title">
                            {data.markdownRemark.frontmatter.masthead.masthead_title}
                        </h1>
                        <p className="mb-5 text-left masthead-lead mb-md-7 pr-xs-0 pr-sm-11 pr-md-4 pr-xl-10">
                            {data.markdownRemark.frontmatter.masthead.masthead_subtitle}
                        </p>
                        {/* <OrderButton mastheadCTA={data.markdownRemark.frontmatter.masthead.masthead_cta}/> */}
                        <CheckoutButton caption="ORDER NOW FOR $69.99" ariaLabel="Order product for $69.99"/>
                        <div>
                        <p className="py-3 pl-2 mb-0 text-left d-inline-block">
                            Need new copies?
                        </p>
                        {/* <ReorderButton className="d-inline-block"/> */}
                        <CheckoutButton link reorder caption="Reorder here." ariaLabel="Reorder product for $34.99"/>
                        </div>
                    </div>
                    <div className="pt-5 pt-sm-0 masthead-image-child">
                        {/* 2. Passing image source to component from the data layer by referencing the named graphql query  --> */}
                        <Image
                            source={data.Image1.childImageSharp.fluid}
                            altText={"Front cover page of Airbnb house manual booklet with coral red decor elements made by Informeasy. The booklet is called Home Guide and the front page contains a picture and the name of the property, address, a QR code for online access and contact details of the host."}
                        />
                    </div>
                </div>
            </div>
            <div className="container px-4 my-6 scroll-down">
                <AnchorLink offset="150" href="#start">
                    <IconScroll height="40px" />
                </AnchorLink>
            </div>
        </section>
    )
}

export default Masthead
