//-----------------------
// S11-Process Component
//-----------------------

// PACKAGES
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

// COMPONENTS
import IconDesign from "../../../assets/icons/Design.inline.svg"
import IconPrint from "../../../assets/icons/Print.inline.svg"

// COMPONENT BUILD
const Process = () => {
    // DATA QUERY
    const data = useStaticQuery(graphql`
        query {
            markdownRemark(frontmatter: {type: {eq: "landing"}}) {
                frontmatter {
                    process {
                        process_title
                        process_subtitle_half_1
                        process_subtitle_half_2
                        process_order_card_title
                        process_order_step_1_title
                        process_order_step_1_copy
                        process_order_step_2_title
                        process_order_step_2_copy
                        process_order_step_3_title
                        process_order_step_3_copy
                        process_order_step_4_title
                        process_order_step_4_copy
                        process_reprint_card_title
                        process_reprint_step_5_title
                        process_reprint_step_5_copy
                        process_reprint_step_6_title
                        process_reprint_step_6_copy
                        process_reprint_step_7_title
                        process_reprint_step_7_copy
                        process_reprint_step_8_title
                        process_reprint_step_8_copy
                    }
                }
              }
        }
    `)

    // JSX TEMPLATE
    return (
        <section className="mb-lg-8">
            <div className="py-9 py-md-10 bg-ie-light">
                <div className="container px-4 text-center">
                    <h2 className="mb-6 underline">{data.markdownRemark.frontmatter.process.process_title}</h2>
                    <p className="lead lead-small text-muted font-italic">
                    {data.markdownRemark.frontmatter.process.process_subtitle_half_1}{" "}<br className="d-none d-md-block" />
                    {data.markdownRemark.frontmatter.process.process_subtitle_half_2}
                    </p>
                </div>
            </div>

            {/* New Orders Timeline */}

            <div className="col mt-n6">
                <div className="row">
                    <div className="container px-4">
                        <div className="flex-row p-4 mx-auto mb-8 shadow col-md-9 col-lg-6 d-flex align-items-center justify-content-center bg-primary">
                            <div className="px-0 mr-4 col- mb-md-0">
                                <IconDesign
                                    className="shrink-icon"
                                    height="80px"
                                />
                            </div>
                            <div className="px-0 col- text-muted">
                                <h5 className="mb-0 text-left text-white timeline header-sans text-md-left">
                                {data.markdownRemark.frontmatter.process.process_order_card_title}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center col">
                <div className="mb-5 text-left row align-items-center text-md-right">
                    <div className="container px-4">
                        <div className="pr-0 mb-4 mr-auto col-md-6 mb-md-0 pl-9 pl-md-0 pr-md-9 pr-lg-11 left-item-top left-item-top--sm">
                            <h3 className="mb-3 mb-md-4 header-sans text-primary left-item--dot">
                                {data.markdownRemark.frontmatter.process.process_order_step_1_title}
                                <span className="left-item--dot-pulse"></span>
                                <span className="pointer-left"></span>
                            </h3>
                            <p>
                            {data.markdownRemark.frontmatter.process.process_order_step_1_copy}
                            </p>
                        </div>
                        <div className="col-md-6"></div>
                    </div>
                </div>

                <div className="mb-5 text-left row align-items-center text-md-left">
                    <div className="container px-4">
                        <div className="col-md-6"></div>
                        <div className="pr-0 mb-4 ml-auto col-md-6 mb-md-0 pl-9 pl-md-0 pl-md-9 pl-lg-11 right-item">
                            <h3 className="mb-3 mb-md-4 header-sans text-primary right-item--dot ">
                            {data.markdownRemark.frontmatter.process.process_order_step_2_title}
                            </h3>
                            <span className="pointer-right"></span>
                            <p>
                            {data.markdownRemark.frontmatter.process.process_order_step_2_copy}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="mb-5 text-left row align-items-center text-md-right">
                    <div className="container px-4">
                        <div className="pr-0 mb-4 mr-auto col-md-6 mb-md-0 pl-9 pl-md-0 pr-md-9 pr-lg-11 left-item">
                            <h3 className="mb-3 mb-md-4 header-sans text-primary left-item--dot">
                            {data.markdownRemark.frontmatter.process.process_order_step_3_title}
                                <span className="pointer-left"></span>
                            </h3>
                            <p>
                            {data.markdownRemark.frontmatter.process.process_order_step_3_copy}
                            </p>
                        </div>
                        <div className="col-md-6"></div>
                    </div>
                </div>

                <div className="mb-5 text-left row align-items-center text-md-left">
                    <div className="container px-4">
                        <div className="order-1 col-md-6 order-md-0"></div>
                        <div className="pr-0 mb-4 ml-auto col-md-6 mb-md-0 pl-9 pl-md-0 pl-md-9 pl-lg-11 bottom-dotted-lg">
                            <h3 className="mb-3 mb-md-4 header-sans text-primary right-item--dot">
                            {data.markdownRemark.frontmatter.process.process_order_step_4_title}
                            </h3>
                            <span className="pointer-right"></span>
                            <p>
                            {data.markdownRemark.frontmatter.process.process_order_step_4_copy}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* // New Reorder Timeline */}
            <div className="col">
                <div className="row">
                    <div className="container px-4">
                        <div className="flex-row p-4 mx-auto mb-8 bg-white shadow col-md-9 col-lg-6 d-flex align-items-center justify-content-center">
                            <div className="px-0 mr-4 col- mb-md-0">
                                <IconPrint
                                    className="shrink-icon"
                                    height="80px"
                                />
                            </div>
                            <div className="px-0 col- text-muted">
                                <h5 className="mb-0 text-left timeline header-sans text-ie-black text-md-left">
                                {data.markdownRemark.frontmatter.process.process_reprint_card_title}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center col">
                <div className="mb-5 text-left row align-items-center text-md-right">
                    <div className="container px-4">
                        <div className="order-1 col-md-6"></div>
                        <div className="pr-0 mb-4 mr-auto col-md-6 mb-md-0 pl-9 pl-md-0 pr-md-9 pr-lg-11 left-item-top left-item-top--sm">
                            <h3 className="mb-4 header-sans text-ie-black left-item--dot">
                            {data.markdownRemark.frontmatter.process.process_reprint_step_5_title}
                                <span className="left-item--dot-pulse"></span>
                                <span className="pointer-left"></span>
                            </h3>
                            <p>
                            {data.markdownRemark.frontmatter.process.process_reprint_step_5_copy}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="mb-5 text-left row align-items-center text-md-left">
                    <div className="container px-4">
                        <div className="col-md-6"></div>
                        <div className="pr-0 mb-4 ml-auto col-md-6 mb-md-0 pl-9 pl-md-0 pl-md-9 pl-lg-11 right-item">
                            <h3 className="mb-3 mb-md-4 header-sans text-ie-black right-item--dot ">
                            {data.markdownRemark.frontmatter.process.process_reprint_step_6_title}
                            </h3>
                            <span className="pointer-right"></span>
                            <p>
                            {data.markdownRemark.frontmatter.process.process_reprint_step_6_copy}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="mb-5 text-left row align-items-center text-md-right">
                    <div className="container px-4">
                        <div className="pr-0 mb-4 mr-auto col-md-6 mb-md-0 pl-9 pl-md-0 pr-md-9 pr-lg-11 left-item">
                            <h3 className="mb-3 mb-md-4 header-sans text-ie-black left-item--dot">
                            {data.markdownRemark.frontmatter.process.process_reprint_step_7_title}
                            </h3>
                            <span className="pointer-left"></span>
                            <p>
                            {data.markdownRemark.frontmatter.process.process_reprint_step_7_copy}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mb-5 text-left row align-items-center text-md-left">
                    <div className="container px-4">
                        <div className="order-1 col-md-6 order-md-0"></div>
                        <div className="pr-0 mb-4 ml-auto col-md-6 mb-md-0 pl-9 pl-md-0 pl-md-9 pl-lg-11 bottom-dotted-sm">
                            <span className="loop-icon"></span>
                            <h3 className="mb-3 mb-md-4 header-sans text-ie-black right-item--dot">
                            {data.markdownRemark.frontmatter.process.process_reprint_step_8_title}
                            </h3>
                            <span className="pointer-right"></span>
                            <p>
                            {data.markdownRemark.frontmatter.process.process_reprint_step_8_copy}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Process
