import React, { useState } from "react"
import getStripe from "../../utils/stripejs"

// const buttonStyles = {
//   fontSize: "13px",
//   textAlign: "center",
//   color: "#000",
//   padding: "12px 60px",
//   boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
//   backgroundColor: "rgb(255, 178, 56)",
//   borderRadius: "6px",
//   letterSpacing: "1.5px",
// }

// const buttonDisabledStyles = {
//   opacity: "0.5",
//   cursor: "not-allowed",
// }

const CheckoutButton = ({secondary, reorder, caption, link, ariaLabel}) => {
  const [loading, setLoading] = useState(false)

  const redirectToCheckout = async event => {
    event.preventDefault()
    setLoading(true)

    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: reorder ? process.env.GATSBY_BUTTON_REORDER_PRICE_ID : process.env.GATSBY_BUTTON_ORDER_PRICE_ID, quantity: 1 }],
      successUrl: `${window.location.origin}/checkout-success/`,
      cancelUrl: `${window.location.origin}/`,
    })

    if (error) {
      console.warn("Error:", error)
      setLoading(false)
    }
  }

  return (
    <button
      className={`snipcart-add-item btn ${link ? "btn-link py-3" : secondary ? "btn-lg btn-secondary text-ie-black badge-pill px-6 mb-5 shadow" : "btn-lg btn-primary text-white badge-pill px-6 mb-5 shadow" }`}
      style={link ? {textDecoration: `underline`} : {lineHeight: `1.9rem`}}
      aria-label={ariaLabel}
      disabled={loading}
      // style={
      //   loading ? { ...buttonStyles, ...buttonDisabledStyles } : buttonStyles
      // }
      onClick={redirectToCheckout}
    >
      {loading ? <span class="spinner-border spinner-border-sm mx-6" role="status" aria-hidden="true"></span> : caption }
    </button>
  )
}

export default CheckoutButton
