//-----------------------
// S14-Features Component
//-----------------------

// PACKAGES
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

// COMPONENTS
import Image from "../../atoms/Image" // Add images by passing files to the Image component instances with  named graphql querys
// import BuyButton from "../../molecules/SnipcartBuyButtonInverse"
import CheckoutButton from "../../molecules/CheckoutButton"

// COMPONENT BUILDs
const CTA = () => {
    // DATA QUERY
    const data = useStaticQuery(graphql`
        query {
            CtaImage: file(relativePath: { eq: "Mockup-Demo1-1280x960.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 900) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
            markdownRemark(frontmatter: {type: {eq: "landing"}}) {
                frontmatter {
                    cta {
                        cta_title
                        cta_subtite
                        cta_cta
                    }
                    pricing {
                        pricing_order_card_CTA
                    }
                }
            }
            
        }
    `)
    // JSX TEMPLATE
    return (
        <section className="overflow-hidden position-static position-lg-relative">
            <div className="px-0 container-fluid">
                <div className="row">
                    <div className="order-2 col-lg-6 order-lg-1">
                        <div className="img-fluid">
                            <Image
                                source={data.CtaImage.childImageSharp.fluid}
                                placeholderStyle={{
                                    filter: `blur(5px)`,
                                }}
                                altText={"House manual booklet and tablet showing the digital house manual laid on a table with a cup of coffee and a large green leaf as decoration."}
                            />
                        </div>
                    </div>
                    <div className="order-1 px-6 py-8 my-auto text-center col-lg-6 order-lg-2 py-lg-0 px-lg-0">
                    <h2 className="card-title" data-config-id="header">
                        {data.markdownRemark.frontmatter.cta.cta_title}
                        </h2>
                        <p
                            className="mb-6 text-center lead lead-small text-muted font-italic"
                            data-config-id="description">
                            {data.markdownRemark.frontmatter.cta.cta_subtite}
                        </p>
                        {/* <BuyButton pricingOrderCTA={data.markdownRemark.frontmatter.pricing.pricing_order_card_CTA} /> */}
                        <CheckoutButton
                            caption={data.markdownRemark.frontmatter.pricing.pricing_order_card_CTA}
                            ariaLabel="Buy this product now for $69.99"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CTA
