//-----------------------
// S7-Features Component
//-----------------------

// PACKAGES
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

// COMPONENTS
import IconCheck from "../../../assets/icons/check.inline.svg" // Tell Webpack this JS file uses this image

// COMPONENT BUILD
const Features = ({ test1 }) => {
    // DATA QUERY
    const data = useStaticQuery(graphql`
        query {
            markdownRemark(frontmatter: {type: {eq: "landing"}}) {
                frontmatter {
                    features {
                        features_title
                        features_subtitle_half_1
                        features_subtitle_half_2
                        features_item_1_title
                        features_item_1_copy
                        features_item_2_title
                        features_item_2_copy
                        features_item_3_title
                        features_item_3_copy
                        features_item_4_title
                        features_item_4_copy
                        features_item_5_title
                        features_item_5_copy
                        features_item_6_title
                        features_item_6_copy
                    }
                }
            }
        }
    `)

    // JSX TEMPLATE
    return (
        <section className="pt-0 pb-8 py-lg-8 ">
            <div className="container px-4">
                <h2 className="mb-6 text-center underline">
                {data.markdownRemark.frontmatter.features.features_title}
                </h2>
                <p className="mb-8 text-center mb-md-9 lead lead-small text-muted font-italic">
                {data.markdownRemark.frontmatter.features.features_subtitle_half_1}{" "}<br className="d-none d-md-block"/>
                {data.markdownRemark.frontmatter.features.features_subtitle_half_2}
                </p>
                <div className="row">
                    <div className="mb-5 col-md-6 col-lg-4 mb-md-7 md-lg-0 d-flex align-items-start">
                        <div className="px-0 mr-5 col-">
                            <IconCheck height="20px"/>
                        </div>
                        <div className="px-0 col-10">
                            <h4 className="mb-3 header-sans text-primary">
                            {data.markdownRemark.frontmatter.features.features_item_1_title}
                            </h4>
                            <p>
                            {data.markdownRemark.frontmatter.features.features_item_1_copy}
                            </p>
                        </div>
                    </div>

                    <div className="mb-5 col-md-6 col-lg-4 mb-md-7 md-lg-0 d-flex align-items-start">
                        <div className="px-0 mr-5 col-">
                            <IconCheck height="20px"/>
                        </div>
                        <div>
                            <h4 className="mb-3 header-sans text-primary">
                            {data.markdownRemark.frontmatter.features.features_item_2_title}
                            </h4>
                            <p>
                            {data.markdownRemark.frontmatter.features.features_item_2_copy}
                            </p>
                        </div>
                    </div>
                    <div className="mb-5 col-md-6 col-lg-4 mb-md-7 md-lg-0 d-flex align-items-start">
                        <div className="px-0 mr-5 col-">
                            <IconCheck height="20px"/>
                        </div>
                        <div>
                            <h4 className="mb-3 header-sans text-primary">
                            {data.markdownRemark.frontmatter.features.features_item_3_title}
                            </h4>
                            <p>
                            {data.markdownRemark.frontmatter.features.features_item_3_copy}
                            </p>
                        </div>
                    </div>
                    <div className="mb-5 col-md-6 col-lg-4 mb-md-7 md-lg-0 d-flex align-items-start">
                        <div className="px-0 mr-5 col-">
                            <IconCheck height="20px"/>
                        </div>
                        <div>
                            <h4 className="mb-3 header-sans text-primary">
                            {data.markdownRemark.frontmatter.features.features_item_4_title}
                            </h4>
                            <p>
                            {data.markdownRemark.frontmatter.features.features_item_4_copy}
                            </p>
                        </div>
                    </div>
                    <div className="mb-5 col-md-6 col-lg-4 mb-md-7 md-lg-0 d-flex align-items-start">
                        <div className="px-0 mr-5 col-">
                            <IconCheck height="20px"/>
                        </div>
                        <div>
                            <h4 className="mb-3 header-sans text-primary">
                            {data.markdownRemark.frontmatter.features.features_item_5_title}
                            </h4>
                            <p>
                            {data.markdownRemark.frontmatter.features.features_item_5_copy}
                            </p>
                        </div>
                    </div>
                    <div className="mb-5 col-md-6 col-lg-4 mb-md-7 md-lg-0 d-flex align-items-start">
                        <div className="px-0 mr-5 col-">
                            <IconCheck height="20px"/>
                        </div>
                        <div>
                            <h4 className="mb-3 header-sans text-primary">
                            {data.markdownRemark.frontmatter.features.features_item_6_title}
                            </h4>
                            <p>
                            {data.markdownRemark.frontmatter.features.features_item_6_copy}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features
