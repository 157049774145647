//-------------
// Index Page
//-------------

// TODO: replace image imports and graqpql query to new gatsby image method

// PACKAGES
import React from "react"
import { Helmet } from "react-helmet"

// COMPONENTS
import Layout from "../layout/Layout"
import Masthead from "../components/organisms/landing/_S1-Masthead"
import Brands from "../components/organisms/landing/_S2-Brands"
import Benefits1 from "../components/organisms/landing/_S3-Benefits"
import Benefits2 from "../components/organisms/landing/_S4-Benefits"
import Benefits3 from "../components/organisms/landing/_S5-Benefits"
import Benefits4 from "../components/organisms/landing/_S6-Benefits"
import Features from "../components/organisms/landing/_S7-Features"
import Showcase from "../components/organisms/landing/_S8-Showcase"
import Benefits5 from "../components/organisms/landing/_S9-Benefits"
import Pricing from "../components/organisms/landing/_S10-Pricing"
import Process from "../components/organisms/landing/_S11-Process"
import FAQ from "../components/organisms/landing/_S12-Faq"
import Testimonials from "../components/organisms/landing/_S13-Testimonials"
import CTA from "../components/organisms/landing/_S14-Cta"

// COMPONENT BUILD
const IndexPage = () => (

    // JSX TEMPLATE
    <>
    <Helmet defer={false} defaultTitle='Informeasy | House manual booklet for vacation rentals'>
        <html lang='en' />
        <link rel="canonical" href='https://www.informeasy.com/' />
        <meta name="viewport" content="initial-scale=1, shrink-to-fit=no, user-scalable=no, width=device-width"/>
        <noscript>Please eneable Javascript or use a modern web browser!</noscript>
        <meta name="description" content='Need more than just a free template? Improve guest experience with a custom made printed house manual booklet that provides information about your property, the area, house rules, safety and recommendations. No monthly instalments. Get your house manual designed and delivered to your doorstep.'></meta>
        <meta name = "keywords" content = "free, wellcome, template, house, manual, home, guide, airbnb, booking, vacation, rental, short-term, hand, book, booklet, improve, guest, experience" />
    </Helmet> 
    <Layout>
        <Masthead />
        <Brands />  
        <Benefits1 />
        <Benefits2 />
        <Benefits3 />
        <Benefits4 />
        <Features />
        <Showcase />
        <Benefits5 />
        <Pricing />
        <Process />
        <FAQ />
        <Testimonials />
        <CTA />
    </Layout>
    </>
)

export default IndexPage

// Change the color of the tracedSVG of images
// traceSVG: { background: "#ffffff", color: "#FE8D87" }