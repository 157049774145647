//-----------------------
// S12-Faq Component
//-----------------------

// TODO: implement gatsby image and inline svg

// PACKAGES
import React from "react"
import { graphql, useStaticQuery } from "gatsby"




// COMPONENTS
import IconChevronDown from "../../../assets/icons/Chevron-down.inline.svg" // Tell Webpack this JS file uses this image

// COMPONENT BUILD
const FAQ = () => {
    // DATA QUERY
    const data = useStaticQuery(graphql`
        query {
            markdownRemark(frontmatter: {type: {eq: "landing"}}) {
                frontmatter {
                    faq {
                        faq_title
                        faq_subtitle
                        faq_question_1
                        faq_answer_1
                        faq_question_2
                        faq_answer_2
                        faq_question_3
                        faq_answer_3
                        faq_question_4
                        faq_answer_4
                        faq_question_5
                        faq_answer_5
                        faq_question_6
                        faq_answer_6
                        faq_question_7
                        faq_answer_7_half_1
                        faq_answer_7_link
                        faq_answer_7_half_2
                    }
                }
              }
        }
    `)

    // JSX TEMPLATE
    return (
        <section className="pt-9 pt-sm-10 pb-9 pb-lg-10" id="faq">
            <div className="container px-4">
                <div className="col mb-9 mb-sm-10 d-block d-md-none border-bottom"></div>
                <h2 className="mb-6 text-center underline">
                {data.markdownRemark.frontmatter.faq.faq_title}
                </h2>
                <p className="mb-8 text-center mb-md-9 lead lead-small text-muted font-italic">
                {data.markdownRemark.frontmatter.faq.faq_subtitle}
                </p>
                <div className="row justify-content-center">
                    <div
                        className="col col-lg-8 col-xl-7 accordion"
                        id="accordion"
                    >
                        <div className="mb-4 border-bottom" id="faq1">
                                <h4 id="faq-heading1-01" className="mb-4 pointer h4-sm">
                                    <a
                                        href="#faq1"
                                        className="px-0 text-left d-flex text-ie-faq header-sans"
                                        type="link"
                                        data-toggle="collapse"
                                        data-target="#faq-collapse1-01"
                                        aria-expanded="true"
                                        aria-controls="faq-collapse1-01"
                                        style={{
                                            whiteSpace: "normal !important",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        <span className="px-0 mr-auto col-">{data.markdownRemark.frontmatter.faq.faq_question_1}</span>
                                        <span className="px-0 col-">
                                        <IconChevronDown
                                            className="pl-3"
                                            height="16px"
                                        />
                                        </span>
                                    </a>
                                </h4>
                            <div
                                className="collapse show"
                                id="faq-collapse1-01"
                                aria-labelledby="faq-heading1-01"
                                data-parent="#accordion"
                            >
                                <p className="mb-7">
                                {data.markdownRemark.frontmatter.faq.faq_answer_1}
                                </p>
                            </div>
                        </div>
                        <div className="mb-4 border-bottom" id="faq2">
                                <h4 id="faq-heading2-01" className="mb-4 pointer h4-sm">
                                    <a
                                        href="#faq2"
                                        className="px-0 text-left d-flex text-ie-faq header-sans"
                                        type="link"
                                        data-toggle="collapse"
                                        data-target="#faq-collapse2-01"
                                        aria-expanded="false"
                                        aria-controls="faq-collapse2-01"
                                        style={{
                                            whiteSpace: "normal !important",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        <span className="px-0 mr-auto col-">{data.markdownRemark.frontmatter.faq.faq_question_2}</span>
                                        <span className="px-0 col-">
                                        <IconChevronDown
                                            className="pl-3"
                                            height="16px"
                                        />
                                        </span>
                                    </a>
                                </h4>
                            <div
                                className="collapse"
                                id="faq-collapse2-01"
                                aria-labelledby="faq-heading2-01"
                                data-parent="#accordion"
                            >
                                <p className="mb-7">
                                {data.markdownRemark.frontmatter.faq.faq_answer_2}
                                </p>
                            </div>
                        </div>
                        <div className="mb-4 border-bottom" id="faq3">
                                <h4 id="faq-heading3-01" className="mb-4 pointer h4-sm">
                                    <a
                                        href="#faq3"
                                        className="px-0 text-left d-flex text-ie-faq header-sans"
                                        type="link"
                                        data-toggle="collapse"
                                        data-target="#faq-collapse3-01"
                                        aria-expanded="false"
                                        aria-controls="faq-collapse3-01"
                                        style={{
                                            whiteSpace: "normal !important",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        <span className="px-0 mr-auto col-">{data.markdownRemark.frontmatter.faq.faq_question_3}</span>
                                        <span className="px-0 col-">
                                        <IconChevronDown
                                            className="pl-3"
                                            height="16px"
                                        />
                                        </span>
                                    </a>
                                </h4>
                            <div
                                className="collapse"
                                id="faq-collapse3-01"
                                aria-labelledby="faq-heading3-01"
                                data-parent="#accordion"
                            >
                                <p className="mb-7">
                                {data.markdownRemark.frontmatter.faq.faq_answer_3}
                                </p>
                            </div>
                        </div>
                        <div className="mb-4 border-bottom" id="faq4">
                                <h4 id="faq-heading4-01" className="mb-4 pointer h4-sm">
                                    <a
                                        href="#faq4"
                                        className="px-0 text-left d-flex text-ie-faq header-sans"
                                        type="link"
                                        data-toggle="collapse"
                                        data-target="#faq-collapse4-01"
                                        aria-expanded="false"
                                        aria-controls="faq-collapse4-01"
                                        style={{
                                            whiteSpace: "normal !important",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        <span className="px-0 mr-auto col-">{data.markdownRemark.frontmatter.faq.faq_question_4}</span>
                                        <span className="px-0 col-">
                                        <IconChevronDown
                                            className="pl-3"
                                            height="16px"
                                        />
                                        </span>
                                    </a>
                                </h4>
                            <div
                                className="collapse"    
                                id="faq-collapse4-01"
                                aria-labelledby="faq-heading4-01"
                                data-parent="#accordion"
                            >
                                <p className="mb-7">
                                {data.markdownRemark.frontmatter.faq.faq_answer_4}
                                </p>
                            </div>
                        </div>
                        <div className="mb-4 border-bottom" id="faq5">
                                <h4 id="faq-heading5-01" className="mb-4 pointer h4-sm">
                                    <a
                                        href="#faq5"
                                        className="px-0 text-left d-flex text-ie-faq header-sans"
                                        type="link"
                                        data-toggle="collapse"
                                        data-target="#faq-collapse5-01"
                                        aria-expanded="false"
                                        aria-controls="faq-collapse5-01"
                                        style={{
                                            whiteSpace: "normal !important",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        <span className="px-0 mr-auto col-">{data.markdownRemark.frontmatter.faq.faq_question_5}</span>
                                        <span className="px-0 col-">
                                        <IconChevronDown
                                            className="pl-3"
                                            height="16px"
                                        />
                                        </span>
                                    </a>
                                </h4>
                            <div
                                className="collapse"
                                id="faq-collapse5-01"
                                aria-labelledby="faq-heading5-01"
                                data-parent="#accordion"
                            >
                                <p className="mb-7">
                                {data.markdownRemark.frontmatter.faq.faq_answer_5}
                                </p>
                            </div>
                        </div>
                        <div className="mb-4 border-bottom" id="faq6">
                                <h4 id="faq-heading6-01" className="mb-4 pointer h4-sm">
                                    <a
                                        href="#faq6"
                                        className="px-0 text-left d-flex text-ie-faq header-sans"
                                        type="link"
                                        data-toggle="collapse"
                                        data-target="#faq-collapse6-01"
                                        aria-expanded="false"
                                        aria-controls="faq-collapse6-01"
                                        style={{
                                            whiteSpace: "normal !important",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        <span className="px-0 mr-auto col-">{data.markdownRemark.frontmatter.faq.faq_question_6}</span>
                                        <span className="px-0 col-">
                                        <IconChevronDown
                                            className="pl-3"
                                            height="16px"
                                        />
                                        </span>
                                    </a>
                                </h4>
                            <div
                                className="collapse"
                                id="faq-collapse6-01"
                                aria-labelledby="faq-heading6-01"
                                data-parent="#accordion"
                            >
                                <p className="mb-7">
                                {data.markdownRemark.frontmatter.faq.faq_answer_6}
                                </p>
                            </div>
                        </div>
                        <div className="mb-4 border-bottom" id="faq7">
                                <h4 id="faq-heading7-01" className="mb-4 pointer h4-sm">
                                    <a
                                        href="#faq6"
                                        className="px-0 text-left d-flex text-ie-faq header-sans"
                                        type="link"
                                        data-toggle="collapse"
                                        data-target="#faq-collapse7-01"
                                        aria-expanded="false"
                                        aria-controls="faq-collapse7-01"
                                        style={{
                                            whiteSpace: "normal !important",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        <span className="px-0 mr-auto col-">{data.markdownRemark.frontmatter.faq.faq_question_7}</span>
                                        <span className="px-0 col-">
                                        <IconChevronDown
                                            className="pl-3"
                                            height="16px"
                                        />
                                        </span>
                                    </a>
                                </h4>
                            <div
                                className="collapse"
                                id="faq-collapse7-01"
                                aria-labelledby="faq-heading7-01"
                                data-parent="#accordion"
                            >
                                <p className="mb-7">
                                {data.markdownRemark.frontmatter.faq.faq_answer_7_half_1}{""}
                                    <button
                                        style={{lineHeight: `1.2`, textDecoration: `underline`}}
                                        type="button"
                                        className="px-1 py-3 nav-link btn btn-link text-ie-primary d-inline-block"
                                        data-toggle="modal"
                                        data-target=".contact"
                                        aria-label="Contact"
                                        >
                                        {data.markdownRemark.frontmatter.faq.faq_answer_7_link}
                                    </button><br/>
                                    {data.markdownRemark.frontmatter.faq.faq_answer_7_half_2}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQ
