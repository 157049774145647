//-----------------------
// S2-Brands Component
//-----------------------

//test

// PACKAGES
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

// SVG
import IconAirbnb from "../../../assets/icons/airbnb.inline.svg" // NOTE: svg comes from a simple uri import and converted into inline svg on build time
import IconBooking from "../../../assets/icons/booking.inline.svg"
import IconTripadvisor from "../../../assets/icons/tripadvisor.inline.svg"
import IconHomeaway from "../../../assets/icons/homeaway.inline.svg"
import IconVrbo from "../../../assets/icons/vrbo.inline.svg"

// COMPONENT BUILD
const Brands = () => {
    // DATA QUERY
    const data = useStaticQuery(graphql`
        query {
            markdownRemark(frontmatter: {type: {eq: "landing"}}) {
                frontmatter {
                    brands_list {
                        brands
                    }
                }
              }
        }
    `)
    // JSX TEMPLATE
    return (
        <section className="pb-md-7 pb-lg-9" id="start">
            <div className="container px-4 mt-6 mt-lg-0">
                <div className="row">
                    <div className="col-md-12">
                        <p className="text-left lead font-italic text-muted d-none d-md-block">
                        {data.markdownRemark.frontmatter.brands_list.brands}
                        </p>
                    </div>
                    <div className="col">
                        <div className="py-8 col px-sm-5 p-md-5 p-lg-6 frame">
                            <div className="row">
                                <div className="mx-auto mb-4 col-12 col-md-2 mb-md-0 d-flex justify-content-center">
                                    <IconAirbnb height="40px" role="img"  aria-label="Rental platform logo"/>
                                </div>
                                <div className="mx-auto mb-4 col-12 col-md-2 mb-md-0 d-flex justify-content-center">
                                    <IconBooking height="40px" role="img"  aria-label="Rental platform logo"/>
                                </div>
                                <div className="mx-auto mb-4 col-12 col-md-2 mb-md-0 d-flex justify-content-center">
                                    <IconTripadvisor height="40px" role="img"  aria-label="Rental platform logo"/>
                                </div>
                                <div className="mx-auto mb-4 col-12 col-md-2 mb-md-0 d-flex justify-content-center">
                                    <IconHomeaway height="40px" role="img"  aria-label="Rental platform logo"/>
                                </div>
                                <div className="mx-auto col-12 col-md-2 d-flex justify-content-center">
                                    <IconVrbo height="40px" role="img"  aria-label="Rental platform logo"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Brands
