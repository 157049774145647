//-----------------------
// S8-Showcase Component
//-----------------------

// PACKAGES
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

// COMPONENTS
import Image from "../../atoms/Image" // Add images by passing files to the Image component instances with  named graphql querys

// SVG
import IconSlideNext from "../../../assets/icons/Slide-nav.inline.svg"

// COMPONENT BUILD
const Showcase = () => {
    // DATA QUERY
    const data = useStaticQuery(graphql`
        query {
            ShowcaseImage1: file(relativePath: { eq: "Showcase-2-3.png" }) {
                childImageSharp {
                    fluid(maxHeight: 870) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            ShowcaseImage2: file(relativePath: { eq: "Showcase-4-5.png" }) {
                childImageSharp {
                    fluid(maxHeight: 870) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            ShowcaseImage3: file(relativePath: { eq: "Showcase-6-7.png" }) {
                childImageSharp {
                    fluid(maxHeight: 870) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            ShowcaseImage4: file(relativePath: { eq: "Showcase-8-9.png" }) {
                childImageSharp {
                    fluid(maxHeight: 870) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            ShowcaseImage5: file(relativePath: { eq: "Showcase-10-11.png" }) {
                childImageSharp {
                    fluid(maxHeight: 870) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            markdownRemark(frontmatter: {type: {eq: "landing"}}) {
                frontmatter {
                    showcase {
                        nav_label
                    }
                }
            }
        }
    `)

    // JSX TEMPLATE
    return (
        <section
            className="pb-8 pt-11 pt-md-9 pt-lg-8 pb-md-6 position-relative gradient"
            id="preview">
            <div className="container px-0">
                <div className="row no-gutters">
                    <div className="mt-8 mb-6 col-md-9 mt-md-0 mb-md-0">
                        <div
                            className="carousel slide"
                            id="carousel-gallery-03"
                            data-touch="true">
                            <div className="carousel-inner">
                                {/* Carousel item 1 */}
                                <div className="carousel-item active">
                                    <Image
                                        source={
                                            data.ShowcaseImage1.childImageSharp
                                                .fluid
                                        }
                                        placeholderStyle={{
                                            filter: `blur(5px)`,
                                        }}
                                        altText={
                                            "First and second inner page of an Informeasy house manual booklet in coral red colour. Those pages contain basic and safety information, a welcome message from the host, directions and a table of contents."
                                        }
                                    />
                                </div>
                                {/* Carousel item 2 */}
                                <div className="carousel-item">
                                    <Image
                                        source={
                                            data.ShowcaseImage2.childImageSharp
                                                .fluid
                                        }
                                        placeholderStyle={{
                                            filter: `blur(5px)`,
                                        }}
                                        altText={
                                            "Third and fourth inner page of an Informeasy house manual booklet in coral red colour. Those pages contain information about different means of local transportation and parking options."
                                        }
                                    />
                                </div>
                                {/* Carousel item 3 */}
                                <div className="carousel-item">
                                    <Image
                                        source={
                                            data.ShowcaseImage3.childImageSharp
                                                .fluid
                                        }
                                        placeholderStyle={{
                                            filter: `blur(5px)`,
                                        }}
                                        altText={
                                            "Fifth and sixth inner page of an Informeasy house manual booklet with a full page map of the area on one side and a list of nearby places on the other."
                                        }
                                    />
                                </div>
                                {/* Carousel item 4 */}
                                <div className="carousel-item">
                                    <Image
                                        source={
                                            data.ShowcaseImage4.childImageSharp
                                                .fluid
                                        }
                                        placeholderStyle={{
                                            filter: `blur(5px)`,
                                        }}
                                        altText={
                                            "Seventh and eight inner page of an Informeasy house manual booklet in coral red colour. Those pages contain restaurant, supermarket and bar recommendations."
                                        }
                                    />
                                </div>
                                {/* Carousel item 5 */}
                                <div className="carousel-item">
                                    <Image
                                        source={
                                            data.ShowcaseImage5.childImageSharp
                                                .fluid
                                        }
                                        placeholderStyle={{
                                            filter: `blur(5px)`,
                                        }}
                                        altText={
                                            "Ninth and tenth inner page of an Informeasy house manual booklet in coral red colour. Those pages contain manuals to home appliances and house rules."
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-3 pb-8 my-auto col-md-3 p-md-0">
                        <div className="d-flex justify-content-center flex-column">
                            <div className="text-center slider-nav-label">
                                <h3 className="mb-2">{data.markdownRemark.frontmatter.showcase.nav_label}</h3>
                            </div>
                            <div className="text-center col">
                                <div className="pulse"></div>
                                <div className="none-pulse d-flex align-items-center justify-content-center">
                                    <a
                                        href="#carousel-gallery-03"
                                        role="button"
                                        data-slide="next">
                                        <button
                                            aria-label="Next image"
                                            style={{ padding: `16px 20px` }}
                                            className="bg-white shadow btn badge-pill">
                                            <IconSlideNext height="18px" />
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Showcase
