//-----------------------
// S5-Benefits Component
//-----------------------

// PACKAGES
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

// SVG
import IconBenefit3 from "../../../assets/icons/benefit-3.inline.svg" // Tell Webpack this JS file uses this image

// COMPONENT BUILD
const Benefits3 = () => {
    // DATA QUERY
    const data = useStaticQuery(graphql`
        query {
            markdownRemark(frontmatter: {type: {eq: "landing"}}) {
                frontmatter {
                    benefits {
                        benefit_3_title
                        benefit_3_copy
                    }
                }
            }
        }
    `)

    // JSX TEMPLATE
    return (
        <section className="pb-7 pb-md-5 py-md-8">
            <div className="container px-4">
                <div className="row">
                    <div className="order-2 my-auto col-sm-9 col-lg-6 order-sm-1">
                        <h2 className="mb-3">
                        {data.markdownRemark.frontmatter.benefits.benefit_3_title}
                        </h2>
                        <p>
                        {data.markdownRemark.frontmatter.benefits.benefit_3_copy}
                        </p>
                    </div>
                    <div className="order-1 mt-4 mb-5 col-sm-3 col-lg-6 order-sm-2 pl-lg-6 mt-md-0 mb-md-0 benefit-line-right d-flex justify-content-sm-center justify-content-lg-start">
                        <IconBenefit3
                            className="float-left mb-auto shrink-icon"
                            height="94px"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Benefits3
