//-----------------------
// S6-Benefits Component
//-----------------------

// PACKAGES
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

// SVG
import IconBenefit4 from "../../../assets/icons/benefit-4.inline.svg" // Tell Webpack this JS file uses this image

// COMPONENT BUILD
const Benefits4 = () => {
    // DATA QUERY
    const data = useStaticQuery(graphql`
        query {
            markdownRemark(frontmatter: {type: {eq: "landing"}}) {
                frontmatter {
                    benefits {
                        benefit_4_title
                        benefit_4_copy
                    }
                }
            }
        }
    `)
    
    // JSX TEMPLATE
    return (
        <section className="pb-9 pb-md-10 pb-lg-8 pt-md-8 ">
            <div className="container px-4">
                <div className="row">
                    <div className="order-1 mt-4 mb-5 col-sm-3 col-lg-6 order-sm-2 order-lg-1 pr-lg-6 mt-md-0 mb-md-0 benefit-line-left d-flex justify-content-sm-center justify-content-lg-end">
                        <IconBenefit4
                            className="float-left mb-auto shrink-icon"
                            height="94px"
                        />
                    </div>
                    <div className="order-2 my-auto col-sm-9 col-lg-6 order-sm-1 order-lg-2">
                        <h2 className="mb-3">
                        {data.markdownRemark.frontmatter.benefits.benefit_4_title}
                        </h2>
                        <p>
                        {data.markdownRemark.frontmatter.benefits.benefit_4_copy}
                        </p>
                    </div>
                </div>
                <div className="mt-8 col d-block d-md-none border-bottom"></div>
            </div>
        </section>
    )
}

export default Benefits4
