//-----------------------
// S9-Benefits Component
//-----------------------

// PACKAGES //
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

// COMPONENTS //
import Image from "../../atoms/Image" // Add images by passing files to the Image component instances with named graphql querys
import IconPDF from "../../../assets/icons/pdf.inline.svg"
import IconQR from "../../../assets/icons/qrcode.inline.svg"
import IconCloud from "../../../assets/icons/cloud.inline.svg"

// COMPONENT BUILD //
const Benefits5 = () => {
    // DATA QUERY
    const data = useStaticQuery(graphql`
        query {
            Image1: file(relativePath: { eq: "Showcase-iPhone.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 700) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
            markdownRemark(frontmatter: {type: {eq: "landing"}}) {
                frontmatter {
                    benefits_digital {
                        benefits_digital_title
                        benefits_digital_1_title
                        benefits_digital_1_copy
                        benefits_digital_2_title
                        benefits_digital_2_copy
                        benefits_digital_3_title
                        benefits_digital_3_copy
                    }
                }
            }
        }
    `)

    // JSX TEMPLATE
    return (
        <section className="pt-10 pb-8">
            <div className="container px-4">
                <h2 className="text-center underline mb-7 mb-lg-9">
                    {data.markdownRemark.frontmatter.benefits_digital.benefits_digital_title}
                </h2>
                <div className="row">
                    <div className="relative mx-auto mt-4 mb-8 mb-lg-4 align-self-center mx-lg-0 col-10 col-md-6 col-lg-4 my-md-0 pb-md-5 pb-lg-7">
                        {/* 2. Passing image source to component from the data layer by referencing the named graphql query  --> */}
                        <Image altText={"Smartphone with an Informeasy house manual appearing on the screen."} source={data.Image1.childImageSharp.fluid} />
                        <a
                            href="https://www.informeasy.com/ebook/informeasy-home-guide-demo"
                            className="px-6 shadow snipcart-add-item btn btn-lg btn-secondary badge-pill text-ie-black"
                            style={{lineHeight: `1.9rem`, position: "absolute", left: "0", right: "0", marginLeft: "auto", marginRight: "auto", width: "fit-content"}}
                            aria-label="Open Example">
                            See Demo eBook
                        </a>
                    </div>
                    <div className="mx-auto col-md-11 col-lg-8 pl-lg-7">
                        <div className="pt-5 row mb-7">
                            <div className="mb-5 text-center col-12 col-md-2 col-lg-2 d-flex justify-content-start">
                                <IconPDF
                                    className="shrink-icon"
                                    height="80px"
                                />
                            </div>
                            <div className="col-12 col-md-10 col-lg-9 pl-md-5">
                                <h4 className="mb-3 header-sans text-ie-black">
                                    {data.markdownRemark.frontmatter.benefits_digital.benefits_digital_1_title}
                                </h4>
                                <p className="mb-3">
                                    {data.markdownRemark.frontmatter.benefits_digital.benefits_digital_1_copy}
                                </p>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <div className="mb-5 text-center col-12 col-md-2 col-lg-2 d-flex justify-content-start">
                                <IconQR className="shrink-icon" height="80px" />
                            </div>
                            <div className="col-12 col-md-10 col-lg-9 pl-md-5">
                                <h4 className="mb-3 header-sans text-ie-black">
                                    {data.markdownRemark.frontmatter.benefits_digital.benefits_digital_2_title}
                                </h4>
                                <p className="mb-3">
                                    {data.markdownRemark.frontmatter.benefits_digital.benefits_digital_2_copy}
                                </p>
                            </div>
                        </div>
                        <div className="row mb-md-7">
                            <div className="mb-5 text-center col-12 col-md-2 col-lg-2 d-flex justify-content-start">
                                <IconCloud
                                    className="shrink-icon"
                                    height="80px"
                                />
                            </div>
                            <div className="col-12 col-md-10 col-lg-9 pl-md-5">
                                <h4 className="mb-3 header-sans text-ie-black">
                                    {data.markdownRemark.frontmatter.benefits_digital.benefits_digital_3_title}
                                </h4>
                                <p className="mb-3">
                                    {data.markdownRemark.frontmatter.benefits_digital.benefits_digital_3_copy}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 col d-block d-md-none border-bottom"></div>
                </div>
            </div>
        </section>
    )
}

export default Benefits5
