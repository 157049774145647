//-----------------------
// S13-Testimonials Component
//-----------------------

/* TODO:
- implement gatsby image and inline svg
- eneable touch scroll on slids
*/

// PACKAGES
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

// COMPONENTS
import Image from "../../atoms/Image" // Add images by passing files to the Image component instances with  named graphql querys

// COMPONENT BUILD
const Testimonials = () => {
    // DATA QUERY
    const data = useStaticQuery(graphql`
        query {
            FeedbackImage1: file(relativePath: { eq: "julia.png" }) {
                childImageSharp {
                    fluid(maxHeight: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
            FeedbackImage2: file(relativePath: { eq: "flavio.png" }) {
                childImageSharp {
                    fluid(maxHeight: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
            FeedbackImage3: file(relativePath: { eq: "lauren.png" }) {
                childImageSharp {
                    fluid(maxHeight: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
            markdownRemark(frontmatter: {type: {eq: "landing"}}) {
                frontmatter {
                    testimonials {
                        testimonials_title
                        testimonials_feedback_1_name 
                        testimonials_feedback_1_city
                        testimonials_feedback_1_copy
                        testimonials_feedback_2_name
                        testimonials_feedback_2_city
                        testimonials_feedback_2_copy
                        testimonials_feedback_3_name
                        testimonials_feedback_3_city
                        testimonials_feedback_3_copy
                    }
                }
            }
        }
    `)

    // JSX TEMPLATE
    return (
        <section className="pt-9 pb-11 py-lg-10 bg-primary">
            {/* Desktop */}
            <div className="container px-4 text-center d-none d-lg-block">
                <h2 className="mb-8 text-white underline-white">
                    {data.markdownRemark.frontmatter.testimonials.testimonials_title}
                </h2>
                <div className="row justify-content-center">
                    <div className="p-5 mt-5 mr-3 border border-white col-6">
                        <div
                            className="mx-auto mb-4 d-block"
                            style={{ width: `100px` }}>
                            <Image
                                className="mb-4 shadow rounded-circle"
                                height="100px"
                                width="100px"
                                source={
                                    data.FeedbackImage1.childImageSharp.fluid
                                }
                                placeholderStyle={{
                                    filter: `blur(5px)`,
                                }}
                                altText={"Testimonial-profil-person-1"}
                            />
                        </div>
                        <p className="mb-2 text-white font-weight-bold">
                        {data.markdownRemark.frontmatter.testimonials.testimonials_feedback_1_name}
                        </p>
                        <p className="mb-4 small text-muted-coral">{data.markdownRemark.frontmatter.testimonials.testimonials_feedback_1_city}</p>
                        <p className="mb-0 text-white font-italic">
                        {data.markdownRemark.frontmatter.testimonials.testimonials_feedback_1_copy}
                        </p>
                    </div>
                    {/* <div className="p-5 mx-3 mt-5 border border-white col">
                        <div
                            className="mx-auto mb-4 d-block"
                            style={{ maxWidth: `100px` }}>
                            <Image
                                className="mb-4 shadow rounded-circle"
                                source={
                                    data.FeedbackImage2.childImageSharp.fluid
                                }
                                placeholderStyle={{
                                    filter: `blur(5px)`,
                                }}
                                altText={"Testimonial-profil-person-2"}
                            />
                        </div>
                        <p className="mb-2 text-white font-weight-bold">
                        {data.markdownRemark.frontmatter.testimonials.testimonials_feedback_2_name}
                        </p>
                        <p className="mb-4 small text-muted-coral">{data.markdownRemark.frontmatter.testimonials.testimonials_feedback_2_city}</p>
                        <p className="mb-0 text-white font-italic">
                        {data.markdownRemark.frontmatter.testimonials.testimonials_feedback_2_copy}
                        </p>
                    </div>
                    <div className="p-5 mt-5 ml-3 border border-white col">
                        <div
                            className="mx-auto mb-4 d-block"
                            style={{ maxWidth: `100px` }}>
                            <Image
                                className="mb-4 shadow rounded-circle"
                                height="100px"
                                width="100px"
                                source={
                                    data.FeedbackImage3.childImageSharp.fluid
                                }
                                placeholderStyle={{
                                    filter: `blur(5px)`,
                                }}
                                altText={"Testimonial-profil-person-3"}
                            />
                        </div>
                        <p className="mb-2 text-white font-weight-bold">
                        {data.markdownRemark.frontmatter.testimonials.testimonials_feedback_3_name}
                        </p>
                        <p className="mb-4 small text-muted-coral">{data.markdownRemark.frontmatter.testimonials.testimonials_feedback_3_city}</p>
                        <p className="mb-0 text-white font-italic">
                        {data.markdownRemark.frontmatter.testimonials.testimonials_feedback_3_copy}
                        </p>
                    </div> */}
                </div>
            </div>

            {/* Mobile */}
            <div className="container text-center d-block d-lg-none">
                <h2 className="mb-8 text-white testimonials-title underline-white">
                {data.markdownRemark.frontmatter.testimonials.testimonials_title}
                </h2>
                <div className="row justify-content-center">
                    <div
                        className="carousel slide"
                        id="carousel-form-01"
                        data-ride="carousel"
                        data-touch="true"
                        data-pause="hover">
                        <ol className="carousel-indicators">
                            <li
                                data-target="#carouselIndicators"
                                data-slide-to="0"
                                className="active"></li>
                            {/* <li
                                data-target="#carouselIndicators"
                                data-slide-to="1"></li>
                            <li
                                data-target="#carouselIndicators"
                                data-slide-to="2"></li> */}
                        </ol>
                        <div className="carousel-inner">
                            <div
                                className="px-3 carousel-item active"
                                data-config-id="slide1_05">
                                <div className="p-5 mt-5 mr-3 border border-white col">
                                    <div
                                        className="mx-auto mb-4 d-block"
                                        style={{ maxWidth: `100px` }}>
                                        <Image
                                            className="mb-4 shadow rounded-circle"
                                            height="100px"
                                            width="100px"
                                            source={
                                                data.FeedbackImage1
                                                    .childImageSharp.fluid
                                            }
                                            placeholderStyle={{
                                                filter: `blur(5px)`,
                                            }}
                                            altText={
                                                "Testimonial-profil-person-1"
                                            }
                                        />
                                    </div>
                                    <p className="mb-2 text-white font-weight-bold">
                                    {data.markdownRemark.frontmatter.testimonials.testimonials_feedback_1_name}
                                    </p>
                                    <p className="mb-4 small text-muted-coral">
                                    {data.markdownRemark.frontmatter.testimonials.testimonials_feedback_1_city}
                                    </p>
                                    <p className="mb-0 text-white font-italic">
                                    {data.markdownRemark.frontmatter.testimonials.testimonials_feedback_1_copy}
                                    </p>
                                </div>
                            </div>
                            {/* <div
                                className="px-3 carousel-item"
                                data-config-id="slide2_05">
                                <div className="p-5 mt-5 mr-3 border border-white col">
                                    <div
                                        className="mx-auto mb-4 d-block"
                                        style={{ maxWidth: `100px` }}>
                                        <Image
                                            className="mb-4 shadow rounded-circle"
                                            height="100px"
                                            width="100px"
                                            source={
                                                data.FeedbackImage2
                                                    .childImageSharp.fluid
                                            }
                                            placeholderStyle={{
                                                filter: `blur(5px)`,
                                            }}
                                            altText={
                                                "Testimonial-profil-person-2"
                                            }
                                        />
                                    </div>
                                    <p className="mb-2 text-white font-weight-bold">
                                    {data.markdownRemark.frontmatter.testimonials.testimonials_feedback_3_name}
                                    </p>
                                    <p className="mb-4 small text-muted-coral">
                                    {data.markdownRemark.frontmatter.testimonials.testimonials_feedback_2_city}
                                    </p>
                                    <p className="mb-0 text-white font-italic">
                                    {data.markdownRemark.frontmatter.testimonials.testimonials_feedback_2_copy}
                                    </p>
                                </div>
                            </div>
                            <div
                                className="px-3 carousel-item"
                                data-config-id="slide3_05">
                                <div className="p-5 mt-5 mr-3 border border-white col">
                                    <div
                                        className="mx-auto mb-4 d-block"
                                        style={{ maxWidth: `100px` }}>
                                        <Image
                                            className="mb-4 shadow rounded-circle"
                                            height="100px"
                                            width="100px"
                                            source={
                                                data.FeedbackImage3
                                                    .childImageSharp.fluid
                                            }
                                            placeholderStyle={{
                                                filter: `blur(5px)`,
                                            }}
                                            altText={
                                                "Testimonial-profil-person-3"
                                            }
                                        />
                                    </div>
                                    <p className="mb-2 text-white font-weight-bold">
                                    {data.markdownRemark.frontmatter.testimonials.testimonials_feedback_3_name}
                                    </p>
                                    <p className="mb-4 small text-muted-coral">
                                    {data.markdownRemark.frontmatter.testimonials.testimonials_feedback_3_city}
                                    </p>
                                    <p className="mb-0 text-white font-italic">
                                    {data.markdownRemark.frontmatter.testimonials.testimonials_feedback_3_copy}
                                    </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonials
